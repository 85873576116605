import { ReactElement, useState } from 'react';
import { Book, Config, createApplication, File, getPayload, request, Summary, useAsyncEffect } from '@topwrite/common';
import { createHistory } from '../lib/history';
import * as models from '../models';
import queryString from 'query-string';
import PageLoader from './page-loader';
import { CssProvider } from '../lib/use-css';
import Page from './page';
import { LogoProvider } from '../lib/use-logo';
import dayjs from 'dayjs';

export default function App() {
    const [app, setApp] = useState<ReactElement>();

    useAsyncEffect(async () => {

        try {
            const { location } = createHistory();

            let payload = getPayload();

            if (!payload) {
                //调试时远程获取
                const response = await request.get(location.toString());
                payload = response.data;
            }

            const {
                id,
                sha,
                summary: rawSummary,
                file,
                config: rawConfig = {},
                options = {},
                lfs,
                pluginCenter
            } = payload;

            const config = Config.createFromObject(rawConfig);

            const summary = Summary.create(rawSummary);

            const book = new Book(id, config, summary, sha);

            const initialState = {
                page: {
                    config: {
                        params: queryString.parse(location.search)
                    },
                    file: new File(file),
                    lfs,
                },
                options
            };

            const Application = await createApplication({
                name: 'reader',
                book,
                models,
                initialState,
                locale: config.getValue('language', 'zh-CN'),
                theme: config.getValue(['themeConfig', 'defaultMode'], 'light'),
                localize: async (locale) => {
                    if (locale.startsWith('zh')) {
                        await import(`dayjs/locale/zh-cn`);
                        dayjs.locale('zh-cn');
                        return import('../lang/zh-CN.json');
                    } else {
                        return import('../lang/en.json');
                    }
                },
                pluginCenter
            });

            const app = <Application>
                <LogoProvider>
                    <CssProvider>
                        <Page />
                    </CssProvider>
                </LogoProvider>
            </Application>;

            setApp(app);
        } catch (e: any) {
            console.error(e);
            if (request.isAxiosError(e)) {
                setApp(e.response?.data || e.message);
            } else {
                setApp(e.message);
            }
        }
    }, []);

    if (app) {
        return app;
    }

    return <PageLoader />;
}
