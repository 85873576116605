import { styled } from '@topwrite/common';

const Spinner = styled.div`
    @keyframes spinner-border {
        to {
            transform: rotate(360deg)
        }
    }

    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid var(--ttw-primary-color, #3c60ff);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
`;

export default Spinner;
