import './extend-entities';
import App from '../components/app';
import { createRoot } from 'react-dom/client';

export default () => {

    const container = document.getElementById('root');

    if (container) {
        const root = createRoot(container);

        root.render(<App />);
    }
}
