import { Markdown as CommonMarkdown, MarkdownProps } from '@topwrite/common';
import { useMemo } from 'react';
import Img from './html/img';
import Attachment from './html/attachment';
import Video from './html/video';
import Audio from './html/audio';
import A from './html/a';
import useCss from '../lib/use-css';
import Spinner from './spinner';

export default function Markdown({ children, components, ...props }: MarkdownProps) {

    const css = useCss();

    return <CommonMarkdown
        {...props}
        css={css}
        loader={<Spinner />}
        components={useMemo(() => {
            return {
                a: A,
                img: Img,
                attachment: Attachment,
                video: Video,
                audio: Audio,
                ...components
            };
        }, [components])}
    >
        {children}
    </CommonMarkdown>;
}
