import { styled } from '@topwrite/common';
import Spinner from './spinner';

export default function PageLoader() {

    return <Container>
        <Spinner />
    </Container>;
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;
